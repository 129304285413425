@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;500;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
/* You can add global styles to this file, and also import other style files */

html,body{ 
	background-color:#FFFFFF; 
} 


/****** 
PITM BRAND COLORS 

Blue 			#28377d
Dark Blue #2d2d42
Yellow 		#f0c945
Red 			#b82a34
Gold 			#b6af2b
Purple 		#9239a2
Green 		#0d9348
Orange 		#cb6a30
Gray 			#6d6d6d

********/ 

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
	width:100%; 
}

footer.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: #0d9948;
  color: white;
	font-size:.8rem; 
	border-top-left-radius:20px; 
	border-top-right-radius:20px; 
}
footer.footer a{ 
	color:#FFF; 
	text-decoration:underline;
} 

h1,h2,h3,h4,h5{ 
	text-transform:uppercase; 
	font-family: 'Oswald', sans-serif;
} 
h6{ 
	font-size:20px; 
	font-weight:bold; 
} 
h1{ 
	color:#28377d; 
	text-shadow: 2px 2px 1px rgba(255,255,255,.7);
	margin-bottom:20px; 
} 
.row .card:first-of-type{ 
	margin-top:0px; 
} 
.row .card.force-top-margin:first-of-type{ 
	margin-top:30px; 
} 
table.terms{ 
	margin-bottom:30px; 
} 
table.terms td:first-of-type{ 
	width:250px; 
} 
table.terms td{ 
	vertical-align:top; 
	border-bottom:1px solid #CCC; 
	padding-top:5px;
	padding-bottom:5px; 
} 

header{ 
	position:relative; 
	width:100%; 
/* 	height:185px;  */
	z-index:1;  
	margin-bottom:20px;
	text-align:center; 
} 
header img{ 
	position:relative; 
	display:block; 
	margin:10px auto; 
} 
header h2{
	font-family: 'Oswald', sans-serif;
	background-color:#28377D; 
	color:white; 
	text-transform:uppercase; 
	text-align:center;
	display:block; 
	margin:0px auto; 
	font-size:1.75rem;
	padding-top:20px;
	padding-bottom:20px;
	width:90%;
	top:40px;
	border-radius:20px; 
	  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
	
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
/*   display: flex; */
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
	margin-right:10px; 
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 21px;
  transition: 0.1s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 25px;
}
.switch-row{ 
	padding-left:15px; 
} 
